<template>
  <div id="component-tab-comparative">
    <div class="d-flex">
      {{/* CARD LIST CAT */}}
      <div class="col-3">
        <CardListCategory
            :list-selected-cat="listSelectedCat"
            :select-cat="selectCat"
            @emit-select-cat="selectedCat"
        />
      </div>

      <div class="col-9">
        <b-card no-body v-if="selectCat">
          <b-tabs card v-model="tabs.activeShopTab" v-on:changed="changeActiveShopTab">
            <b-tab active>
              <template #title>
                {{ (tabs.firstShopSelected.name) ? tabs.firstShopSelected.name : 'Premier Magasin' }}
              </template>
              <b-card-text>
                <div class="d-flex" v-if="!tabs.firstShopSelected.id">
                  {{/* CARD LIST CENTER */}}
                  <div class="col-4">
                    <CardListCenter
                        :select-center="selectCenter"
                        :select-shop="selectShop"
                        @emit-select-center="selectedCenter"
                        v-if="selectCat"
                    />
                  </div>

                  {{/* CARD LIST SHOP */}}
                  <div class="col-8">
                    <CardListShop
                        class="ml-2"
                        :select-center="selectCenter"
                        :select-shop="selectShop"
                        :list-shops-center="listShopsCenter"
                        @emit-select-shop="selectedShop"
                        v-if="selectCenter"
                    />
                  </div>
                </div>
                <div v-else>
                  <CardShopSelected
                      :shop="tabs.firstShopSelected"
                      :is-list="false"
                      :index="null"
                      @emit-change-shop="changeFirstShop"
                  />
                </div>

                {{/* ACTION VALIDATE */}}
                <div class="d-flex justify-content-end mt-2 mr-3" v-if="selectShop" v-on:click="validateFirstShop">
                  <button class="btn btn-success">Valider le choix du magasin</button>
                </div>
              </b-card-text>
            </b-tab>
            <b-tab
                v-for="i in tabs.listTabShop"
                :key="'dyn-tab-' + i"
                :title="(tabs.listSelectedShop[i]) ? tabs.listSelectedShop[i].shop.name : 'Choix magasin n° ' + (i + 2)"
            >
              <b-card-text>
                <div class="d-flex" v-if="!tabs.listSelectedShop[i]">
                  {{/* CARD LIST CENTER */}}
                  <div class="col-4">
                    <CardListCenter
                        :select-center="selectCenter"
                        @emit-select-center="selectedCenter"
                        v-if="selectCat"
                    />
                  </div>

                  {{/* CARD LIST SHOP */}}
                  <div class="col-8">
                    <CardListShop
                        class="ml-2"
                        :select-center="selectCenter"
                        :select-shop="selectShop"
                        :list-shops-center="listShopsCenter"
                        :list-selected-shop="tabs.listSelectedShop"
                        :first-select-shop="tabs.firstShopSelected"
                        @emit-select-shop="selectedShop"
                        v-if="selectCenter"
                    />
                  </div>
                </div>
                <div v-else>
                  <CardShopSelected
                      :shop="tabs.listSelectedShop[i].shop"
                      :is-list="true"
                      :index="i"
                      @emit-change-shop="changeShop"
                      @emit-remove-shop="removeShop"
                  />
                </div>

                {{/* ACTION VALIDATE */}}
                <div class="d-flex justify-content-end">
                  {{/* CLOSE TABS */}}
                  <b-button
                      size="sm"
                      variant="warning"
                      class="float-right mt-2 mb-2 ml-2"
                      @click="closeTabShop(i)"
                      v-if="i >= 1 && !tabs.listSelectedShop[i]"
                  >
                    <b-icon class="" icon="x" font-scale="1" variant="light"></b-icon>
                  </b-button>
                  <div class="d-flex justify-content-end mt-2 mr-3 ml-2" v-if="selectShop">
                    <button class="btn btn-sm btn-success" v-on:click="validateShop(false)">
                      Valider le choix du magasin
                    </button>
                    <button class="btn btn-sm btn-info ml-2" v-on:click="validateShop(true)">
                      Ajouter un nouveaux magasin
                    </button>
                  </div>
                </div>

              </b-card-text>
            </b-tab>
            <!-- New Tab Button (Using tabs-end slot) -->
            <template #tabs-end>
              <b-nav-item role="presentation" @click.prevent="newTabShop" href="#"><b>+</b></b-nav-item>
            </template>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    listSelectedCat: Array
  },
  data() {
    return {
      tabs: {
        counterShop: 0,
        activeShopTab: 0,
        firstShopSelected: {},
        firstShopIsChanged: false,
        listTabShop: [],
        listSelectedShop: [],
        changedFirstShop: false,
        isChangedShop: false,
      },
      selectCat: null,
      selectCenter: null,
      selectShop: null,
      listShopsCenter: [],
      listComparative: []
    }
  },
  watch: {
    selectCat(newValue) {
      this.selectCat = newValue
      this.selectCenter = null
      this.selectShop = null
      this.listShopsCenter = []
    },
    selectCenter(newValue) {
      this.selectCenter = newValue
      this.selectShop = null
      this.listShopsCenter = this.getListShopByCenter(this.selectCat, this.selectCenter)
    },
    selectShop(newValue) {
      this.selectShop = newValue
    }
  },
  methods: {
    /*----------------- SELECT CAT ------------------*/
    selectedCat(parentCat) {
      this.selectCat = parentCat
      this.listShopsCenter = this.getListShopByCenter(this.selectCat, this.selectCenter)
    },
    /*----------------- SELECT CENTER ------------------*/
    selectedCenter($event) {
      this.selectCenter = Number($event.target.getAttribute('data-id'))
      this.listShopsCenter = this.getListShopByCenter(this.selectCat, this.selectCenter)
    },
    /*----------------- SELECT SHOP ------------------*/
    selectedShop($event) {
      this.selectShop = Number($event.target.getAttribute('data-id'))
    },
    /*----------------- VALIDATE SHOP ------------------*/
    validateFirstShop() {
      let model = {
        cat: this.selectCat,
        center: this.selectCenter,
        shop: this.selectShop
      }

      // Push in listComparative
      this.listComparative.push(model)
      // Handle title tabs
      this.tabs.firstShopSelected = this.getShop(this.selectShop)

      // Create new Tab on list tab shop
      if (!this.tabs.firstShopIsChanged) {
        this.newTabShop()
      } else {
        this.$emit('emit-new-shop-on-changed', {
          cat: this.selectCat,
          center: this.selectCenter,
          newShop: this.selectShop
        })

        this.tabs.firstShopIsChanged = false
      }

      // Reset select
      this.selectCenter = null
      this.selectShop = null
    },
    changeFirstShop(shopSelected) {
      this.$swal({
        title: 'Voulez vous enlever ce magasin de la comparaison ?',
        text: 'En validant vous allez devoir changer de magasin, actuellement choisi : ' + shopSelected.name,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2FA76E',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Valider',
        cancelButtonText: 'Annuler'
      }).then((result) => {
        if (result.value) {
          // Reset first Tab
          this.listComparative.splice(this.listComparative.findIndex(shops => shops === shopSelected.id))
          this.selectCenter = null
          this.selectShop = null
          this.tabs.firstShopSelected = {}
          this.tabs.firstShopIsChanged = true

          this.$emit('emit-change-shop', {
            cat: this.selectCat,
            oldShop: shopSelected,
            isFirstShop: true
          })
        }
      })
    },
    validateShop(isNewTab) {
      let model = {
        cat: this.selectCat,
        center: this.selectCenter,
        shop: this.selectShop
      }

      this.tabs.listSelectedShop.push({
        cat: this.selectCat,
        center: this.selectCenter,
        shop: this.getShop(this.selectShop)
      })

      // Push in listComparative
      this.listComparative.push(model)

      // New tab
      if (isNewTab) {
        // Create new Tab on list tab shop
        this.newTabShop()
      } else {
        if (!this.tabs.isChangedShop) {
          this.$swal({
            title: 'Voulez-vous valider cette comparaison ?',
            text: 'En validant vous allez passer à la prochaine comparaison',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2FA76E',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Valider',
            cancelButtonText: 'Annuler'
          }).then((result) => {
            if (result.value) {
              this.$emit('emit-new-tab-comparative', {
                listComparative : this.listComparative,
                isManual: false
              })
            }
          })
        } else {
          this.$emit('emit-new-shop-on-changed', {
            cat: this.selectCat,
            center: this.selectCenter,
            newShop: this.selectShop
          })
        }
      }

      // Reset select
      this.selectCenter = null
      this.selectShop = null
      this.tabs.isChangedShop = false
    },
    changeShop(shopSelected) {
      this.$swal({
        title: 'Voulez vous changer ce magasin de la comparaison ?',
        text: 'En validant vous allez devoir changer de magasin, actuellement choisi : ' + shopSelected.name,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2FA76E',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Valider',
        cancelButtonText: 'Annuler'
      }).then((result) => {
        if (result.value) {
          // Reset first Tab
          this.listComparative.splice(this.listComparative.findIndex(shop => shop === shopSelected.id))
          this.tabs.listSelectedShop.splice(this.tabs.listSelectedShop.findIndex(shops => shops.id === shopSelected.id))

          this.selectCenter = null
          this.selectShop = null
          this.tabs.isChangedShop = true

          this.$emit('emit-change-shop', {
            cat: this.selectCat,
            oldShop: shopSelected,
            isFirstShop: false
          })
        }
      })
    },
    removeShop(model) {
      this.$swal({
        title: 'Voulez vous enlever ce magasin de la comparaison ?',
        text: 'En validant vous allez devoir changer de magasin, actuellement choisi : ' + model.shop.name,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2FA76E',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Valider',
        cancelButtonText: 'Annuler'
      }).then((result) => {
        if (result.value) {
          this.listComparative.splice(this.listComparative.findIndex(shops => shops === model.shop.id))
          this.tabs.listSelectedShop.splice(this.tabs.listSelectedShop.findIndex(shops => shops.id === model.shop.id))
          this.closeTabShop(model.index)
        }
      })
    },
    newTabShop() {
      this.tabs.listTabShop.push(this.tabs.counterShop ++)
    },
    changeActiveShopTab() {
      this.tabs.activeShopTab = this.tabs.listTabShop.length
    },
    closeTabShop(index) {
      for (let i = 0; i < this.tabs.listTabShop.length; i++) {
        if (this.tabs.listTabShop[i] === index) {
          this.tabs.listTabShop.splice(i, 1)
        }
      }

      this.tabs.counterShop --
    },
    /*----------------- GETTERS ------------------*/
    getCat(catId) {
      return this.$store.getters.getParentCategoryById(catId)
    },
    getCenter(centerId) {
      return this.$store.getters.getCenterById(centerId)
    },
    getShop(shopId) {
      let getShop = null
      this.selectCat.childs.forEach((child) => {
        child.shops.forEach((shop) => {
          if (shop.id === shopId) {
            getShop = shop
          }
        })
      })

      return getShop
    },
    /*----------------- PRIVATE FUNCTION ------------------*/
    getListShopByCenter(parentCat, centerId) {
      let children = parentCat.childs

      let listShop = []
      children.forEach((child) => {
        child.shops.forEach((shop) => {
          if (shop.center.id === centerId) {
            listShop.push(shop)
          }
        })
      })

      return listShop
    },
  },
  components: {
    CardListCategory: () => import('../../components/exportComparative/cardListCategory'),
    CardListCenter: () => import('../../components/exportComparative/cardListCenter'),
    CardListShop: () => import('../../components/exportComparative/cardListShop'),
    CardShopSelected: () => import('../../components/exportComparative/cardShopSelected'),
  }
}
</script>
